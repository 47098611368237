// ==============================
//* CONTENT SEE MORE
//
//? This file manages accordion behavior.
// ==============================

$(() => {
    const seemoreContent = ".seemore__content";
    const seemoreText = ".seemore__content--text";
    const seemoreBtn = ".seemore__content--btn";

    $(seemoreBtn).on("click", function (e) {
        e.preventDefault();
        $(this).toggleClass("show").closest(seemoreContent).find(seemoreText).slideToggle();
    });
});
